import { CookieService } from 'ngx-cookie-service'
import { take, takeUntil } from 'rxjs/operators'
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { NavigationEnd, Router } from '@angular/router'
import { GoogleTagManagerService } from 'angular-google-tag-manager-sham'
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker'
import { filter, Subject } from 'rxjs'
import * as Sentry from '@sentry/browser'
import { OverlayContainer } from '@angular/cdk/overlay'
import { StoreService, User, HubspotService, TokenService } from 'shared-lib'

@Component({
  selector: 'app-root',
  template: `
    <app-cookie-consent></app-cookie-consent>
    <div class="app-component" [ngClass]="currentTheme">
      <ngx-guided-tour></ngx-guided-tour>
      <router-outlet></router-outlet>
    </div>`
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor (
    private translateService: TranslateService,
    private storeService: StoreService,
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private swUpdate: SwUpdate,
    private cookieService: CookieService,
    private overlayContainer: OverlayContainer,
    private hubspotService: HubspotService,
    private tokenService: TokenService
  ) { }

  public currentTheme = null
  private destroy$: Subject<void> = new Subject()

  ngOnDestroy (): void {
    this.destroy$.next()
    this.setTheme(null)
  }

  ngOnInit (): void {

    this.initHubspot()
    this.initLanguage()
    this.initSessionRefresher()
    this.initGoogleTagManagerTracking()
  }

  ngAfterViewInit (): void {
    this.initMsClarity()
    this.initSentry()
    this.initVersionUpdate()
  }

  private initSessionRefresher () {
    this.tokenService.initActivityListener()
  }

  private initMsClarity () {
    this.addMsClarityTagsToSentry()
  }

  private initVersionUpdate() {
    this.swUpdate.versionUpdates
      .pipe(
        takeUntil(this.destroy$),
        filter((evt: any): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
      )
      .subscribe(() => {
        if (this.router.url.includes('auth')) {
          window.location.reload()
        } else {
          this.reloadOnNextNavigation()
        }
      })
  }

  private initHubspot () {
    this.hubspotService.initHubspotSettings()
  }

  private initLanguage () {
    this.translateService.setDefaultLang('en')
    this.translateService.addLangs(['en', 'sv', 'es'])

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.storeService.user$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        (user: User) => {
          this.translateService.use(user.language)
        }
      )
  }

  private reloadOnNextNavigation () {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1) // Tomar solo el primer evento que cumpla con la condición
      )
      .subscribe(() => {
        window.location.reload()
      })
  }

  private initGoogleTagManagerTracking () {
    // Google Tag Manager
    this.router.events
      .pipe(
        takeUntil(this.destroy$)
      )
      .forEach(item => {
        if (item instanceof NavigationEnd) {
          const gtmTag = {
            event: 'page',
            pageName: item.url
          }
          this.gtmService.pushTag(gtmTag)
        }
      })
  }

  private getMsClarityId (): string | null {
    const msClarityCookieData = this.cookieService.get('_clck')
    return msClarityCookieData?.split('|')[0] || null
  }

  private addMsClarityTagsToSentry () {
    const msClarityId = this.getMsClarityId()
    const msClarityLink = `https://clarity.microsoft.com/projects/view/fd67i0zkkm/impressions?&UserId=is%3B${msClarityId}`

    if (!msClarityId) return

    Sentry.addBreadcrumb({
      type: 'debug',
      category: 'started',
      level: 'info',
      message: 'Microsoft Clarity ID: ' + msClarityLink,
      data: {
        msClarityLink
      }
    })

    Sentry.withScope(scope => {
      scope.setTag('clarityUserId', msClarityId)
    })
  }

  private setTheme (themeClassName: string = 'default-theme'): void {
    // Validate theme class name
    if (!themeClassName || typeof themeClassName !== 'string' || themeClassName.trim() === '') {
      console.error('Invalid theme class name:', themeClassName)
      return
    }

    if (this.overlayContainer && this.overlayContainer.getContainerElement()) {
      const overlayClassList = this.overlayContainer.getContainerElement().classList

      // Only remove the current theme if it exists and is a non-empty string
      if (this.currentTheme && this.currentTheme.trim() !== '') {
        overlayClassList.remove(this.currentTheme)
      }

      overlayClassList.add(themeClassName)
      this.currentTheme = themeClassName
    } else {
      console.error('Overlay container is not available')
    }
  }
  

  private initSentry(){

    // check if 
    return Sentry.init({
      dsn: 'https://d48fd49c4a5b40f3a13e9bca89bf8e1a@o450860.ingest.sentry.io/5466420',
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        Sentry.browserTracingIntegration(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
      tracePropagationTargets: [
        "ngshoutlyapp.dev",
        "gigs.shoutly.com",
        "test.shoutly.com",
        "stage.shoutly.com",
        "demo.shoutly.com",
        "ngshoutlyapp.dev",
        /^https:\/\/ngshoutlyapp\.dev\/.*$/,
        /^https:\/\/api-test\.shoutly\.com\/.*$/,
        /^https:\/\/api-stage\.shoutly\.com\/.*$/,
        /^https:\/\/api-demo\.shoutly\.com\/.*$/,
      ],
    })
  }
  
}
